

const Thanks = (props) => {


    return(
        <div className="Thanks container">
            <h1>Thank you for submitting the form!</h1>
            <p>May the Force be with you as we process your information faster than the Millennium Falcon completing the Kessel Run.</p>
        </div>
    )
};

export default Thanks;


//